import React from 'react'
import {
  Layout,
  Navbar,
  Box,
  Container,
  Text,
  Flex,
  Image,
  Footer,
  Reveal,
} from '../components'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { injectIntl, IntlShape } from 'gatsby-plugin-intl'

// Styled components

interface ISectionBackgroundProps {
  reverse?: boolean
}

const SectionBackground = styled.div<ISectionBackgroundProps>`
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  position: absolute;
  top: 50%;
  left: 50%;

  transform: translateY(-50%)
    ${(props) =>
      props.reverse ? 'translateX(-40%) scaleX(-1)' : 'translateX(-65%)'};

  width: 40em;
  height: 40em;

  z-index: -1;

  @media (max-width: ${(props) => props.theme.media.lg}) {
    transform: translateY(-20%)
      ${(props) =>
        props.reverse ? 'translateX(-40%) scaleX(-1)' : 'translateX(-65%)'};
  }
`

// Custom components

interface ISingleServiceProps {
  image: string
  backgroundImage: string
  title: string
  text: string
  reverse?: boolean
}

const SingleService: React.SFC<ISingleServiceProps> = (props) => {
  return (
    <Flex
      direction={props.reverse ? 'row-reverse' : 'row'}
      alignItems='center'
      justifyContentLg='center'
      wrapLg='wrap'
    >
      <Box flex='0 0 auto' flexLg='0 0 100%' textAlignLg='center'>
        <Box
          padding={props.reverse ? '0em 0em 0em 8em' : '0em 8em 0em 0em'}
          paddingLg='0em'
          style={{ position: 'relative' }}
        >
          <SectionBackground
            style={{ backgroundImage: `url(${props.backgroundImage})` }}
            reverse={props.reverse}
          />

          <Image src={props.image} widthLg='10em' tilt />
        </Box>
      </Box>

      <Box flex='0 1 auto'>
        <Box paddingLg='2em 0em 0em 0em'>
          <Text
            color='white'
            size='2em'
            weight={300}
            lineHeight={1.5}
            letterSpacing='0.05em'
          >
            {props.title}
          </Text>
        </Box>

        <Box margin='1em 0em 0em 0em'>
          <Text
            color='silver'
            size='1.2em'
            lineHeight={1.5}
            letterSpacing='0.05em'
            maxWidth='28.5em'
          >
            {props.text}
          </Text>
        </Box>
      </Box>
    </Flex>
  )
}

// Main component

export const query = graphql`
  query {
    secBackgroundImage: file(relativePath: { eq: "sec_background.jpg" }) {
      ...getImage
    }

    iconArchitecture: file(relativePath: { eq: "icon-architecture.svg" }) {
      ...getImage
    }

    iconUi: file(relativePath: { eq: "icon-ui.svg" }) {
      ...getImage
    }

    iconUx: file(relativePath: { eq: "icon-ux.svg" }) {
      ...getImage
    }

    iconFrontend: file(relativePath: { eq: "icon-frontend.svg" }) {
      ...getImage
    }

    iconMaintenance: file(relativePath: { eq: "icon-maintenance.svg" }) {
      ...getImage
    }
  }
`

interface IServicesPageProps {
  data: any
  intl: IntlShape
}

const ServicesPage: React.SFC<IServicesPageProps> = (props) => (
  <Layout>
    <>
      <Navbar />

      <Container>
        <Box padding='12em 0em 8em 0em' paddingSm='3em 0em 3em 0em'>
          <Reveal>
            <SingleService
              image={props.data.iconArchitecture.publicURL}
              backgroundImage={props.data.secBackgroundImage.publicURL}
              title={props.intl.formatMessage({ id: 'services.titleUxDesign' })}
              text={props.intl.formatMessage({
                id: 'services.contentUxDesign',
              })}
            />
          </Reveal>
        </Box>

        <Box padding='6em 0em 8em 0em' paddingSm='3em 0em 3em 0em'>
          <Reveal>
            <SingleService
              image={props.data.iconUi.publicURL}
              backgroundImage={props.data.secBackgroundImage.publicURL}
              title={props.intl.formatMessage({ id: 'services.titleUiDesign' })}
              text={props.intl.formatMessage({
                id: 'services.contentUiDesign',
              })}
              reverse
            />
          </Reveal>
        </Box>

        <Box padding='6em 0em 8em 0em' paddingSm='3em 0em 3em 0em'>
          <Reveal>
            <SingleService
              image={props.data.iconUx.publicURL}
              backgroundImage={props.data.secBackgroundImage.publicURL}
              title={props.intl.formatMessage({
                id: 'services.titleUxResearch',
              })}
              text={props.intl.formatMessage({
                id: 'services.contentUxResearch',
              })}
            />
          </Reveal>
        </Box>

        <Box padding='6em 0em 8em 0em' paddingSm='3em 0em 3em 0em'>
          <Reveal>
            <SingleService
              image={props.data.iconFrontend.publicURL}
              backgroundImage={props.data.secBackgroundImage.publicURL}
              title={props.intl.formatMessage({
                id: 'services.titleUiEngineering',
              })}
              text={props.intl.formatMessage({
                id: 'services.contentUiEngineering',
              })}
              reverse
            />
          </Reveal>
        </Box>

        <Box padding='6em 0em 8em 0em' paddingSm='3em 0em 3em 0em'>
          <Reveal>
            <SingleService
              image={props.data.iconMaintenance.publicURL}
              backgroundImage={props.data.secBackgroundImage.publicURL}
              title={props.intl.formatMessage({
                id: 'services.titleMaintenance',
              })}
              text={props.intl.formatMessage({
                id: 'services.contentMaintenance',
              })}
            />
          </Reveal>
        </Box>

        <Box padding='8em 0em 8em 0em' paddingSm='8em 0em 8em 0em'>
          <Reveal>
            <Footer />
          </Reveal>
        </Box>
      </Container>
    </>
  </Layout>
)

export default injectIntl(ServicesPage)
